// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\TeamCity\\buildAgent\\work\\77aa463b521e2a35\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-to-register-js": () => import("C:\\TeamCity\\buildAgent\\work\\77aa463b521e2a35\\src\\pages\\how-to-register.js" /* webpackChunkName: "component---src-pages-how-to-register-js" */),
  "component---src-pages-index-js": () => import("C:\\TeamCity\\buildAgent\\work\\77aa463b521e2a35\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styleguide-js": () => import("C:\\TeamCity\\buildAgent\\work\\77aa463b521e2a35\\src\\pages\\styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\TeamCity\\buildAgent\\work\\77aa463b521e2a35\\.cache\\data.json")

